<template>
  <div>
    <!-- 沉浸式头部 -->
    <nav-bar :header-title="AI.activityName" backColor="#fff" :show-back="true" :header-back="newAppBack"
      @setBarHeight="setBarHeight" />
    <!-- 预告 -->
    <div v-if="AI.activityTrailer" class="marketing-preview col-start-center">
      <img :src="item" v-for="item in AI.activityTrailer.imgList" :key="item" style="width: 100%" alt="" />
      <van-count-down v-if="AI.activityTrailer.previewTime" style="opacity: 0" @finish="previewFinish"
        :time="AI.activityTrailer.previewTime" />
    </div>
    <!-- 主会场 -->
    <div v-else class="marketing-template-2023 col-start-center" :style="`background-color:#DE2813`">
      <div class="background">
        <!-- 主视觉 -->
        <img :src="baseImgUrl + 'top.png'" class="head-img" />
        <div class="top-button">
          <!-- 分享按钮 -->
          <div class="button f24" :style="`background: ${AI.shopStyle.shareIconColor}`" @click="showShare">分享</div>
          <!-- 规则按钮图标 -->
          <div class="button f24" :style="`background: ${AI.shopStyle.ruleIconColor}`" @click="openRule(true)">规则
          </div>
        </div>
        <!-- 右上角的悬浮按钮 -->
        <img @click="showGift" v-if="giftList.length > 0" :src="baseImgUrl + 'gift.png'" alt="" class="float-icon" />
        <!-- 倒计时 -->
        <count-down class="down-time" v-if="AI.shopStyle.headerCountdown" :timeObj="AI" :status="activityStatus"
          @timeFinish="timeFinish"></count-down>
      </div>
      <!-- 带货人 信息 -->
      <div class="invite-block row-start-center f22"
        v-if="AI.shopStyle.inviterStatus && (inviteUserInfo.shareTeacherId || inviteUserInfo.shareUserId)">
        <div class="invite-head"><img :src="`${inviteUserInfo.shareUserHeadImg}`" alt="" /></div>
        <div class="invite-name ellipsis" :style="`color: ${AI.shopStyle.subfieldNameColor};`">
          <span>{{ inviteUserInfo.shareTitle }}</span> 邀请你参与{{ AI.activityName }}
        </div>
      </div>
      <div class="template-content col-start-center">
        <div class="good-block">
          <div v-for="(good, index) in curGoods.groupInfos[0].goodsInfos">
            <div class="good" :class="`itembg${index}`"
              :style="`background-image: url(${goodsStatusMap[good.id] == 'EXPIRED' ? replaceFun(good.cardImgIneffective, idenx) : replaceFun(good.goodsImg, index)});`">
              <span class="btn" :class="goodsStatusMap[good.id]" @click="buyCard(good)"
                v-if="goodsStatusMap[good.id] != 'EXPIRED'">{{
                  btnJson[goodsStatusMap[good.id]] }}
              </span>
            </div>
          </div>
        </div>
        <div class="end f22">已经滑到底部啦</div>
      </div>
      <!-- 规则 弹窗 -->
      <van-popup v-model="isRuleShow" position="bottom" safe-area-inset-bottom class="pop-rule">
        <div class="pop-rule-title f32 fw6">
          活动规则
          <van-icon name="cross" class="pop-rule-close f28" color="#fff" @click="isRuleShow = false" />
        </div>
        <div class="pop-rule-content f26">
          <p>一、主活动规则</p>
          <p>活动规则</p>
          <p>1、春节购活动时间:2025年1月13日10:00 - 2025年1月26日24:00；数量有限，先到先得；</p>
          <p>240元/360元私教课包与包月私教预售时间：</p>
          <p>1月14日22：00-1月19日24：00</p>
          <p>此期间内无法兑换，请用户知悉</p>
          <p class="pt">可兑换时间：1月21日下午15：00-2月28日24：00</p>
          <p>2、超鹿月卡售卖时间：2025年1月13日10:00 - 2025年1月26日24：00</p>
          <p>3、团课课包、私教课包、包月私教售卖时间截止：2025年1月19日24:00</p>
          <p>4、商城内所有产品，均为实体卡，设置有效期，请在有效期内尽快至门店兑换，逾期将失效；</p>
          <p>5、活动最终解释权归超鹿所有。如有疑问可联系客服:400-700-5678。</p>
          <p class="pt">二、超鹿月卡活动规则</p>
          <p>1、购买后商品查看渠道：APP 我的>优惠券>实物券</p>
          <p>2、实物券实物包含：毛毡礼品袋/超鹿月卡实体卡套装/三只松鼠1618g坚果礼盒；</p>
          <p>限超鹿福州城市门店使用；请于2025年2月28日前凭此券至门店兑换实物；每个ID限购3份，仅限兑换3次；</p>
          <p>3、使用该卡，除高峰时段(19:00-21:00)的其他时间段，可享受免费自助健身;每30天赠送12小时高峰时段(19:00-21:00)免费自助健身时长，超出12小时部分以7折计算自助健身费用。</p>
          <p>4、本实物券限用一次，不可提现，不可退款，不可转赠。</p>
          <p>5、活动最终解释权归超鹿所有。如有疑问可联系客服:400-700-5678。</p>
          <p class="pt">三、团课课包活动规则</p>
          <p>1、购买后商品查看渠道：APP 我的>优惠券>实物券</p>
          <p>2、实物券实物包含：4节48元团课课包实体卡套装/三只松鼠鼠你最牛实物大礼包；</p>
          <p>请于2025年2月12日前凭此券至门店兑换实物；每个ID限购3份；</p>
          <p>3、实物券不可提现，不可退款，不可转赠。课包到账后亦不可提现，不可退款，不可转赠。</p>
          <p>4、活动最终解释权归超鹿所有。如有疑问可联系客服:400-700-5678。</p>
          <p class="pt">四、私教课包活动规则</p>
          <p>1、购买后商品查看渠道：APP 我的>优惠券>实物券</p>
          <p>2、实物券实物包含：3节/5节299元私教课包实体卡/信封/毛毡礼品袋/超鹿咖啡杯礼盒（咖啡杯、蛋白粉杯、冰箱贴）；</p>
          <p>请于2025年2月28日前凭此券至门店兑换实物；每个ID限购3份；</p>
          <p>3、实物券不可提现，不可退款，不可转赠。课包到账后亦不可提现，不可退款，不可转赠。</p>
          <p>4、活动最终解释权归超鹿所有。如有疑问可联系客服:400-700-5678。</p>
          <p class="pt">五、包月私教活动规则</p>
          <p>1、购买后商品查看渠道：APP 我的>优惠券>实物券</p>
          <p>2、实物券实物包含：299元包月私教实体卡/信封/毛毡礼品袋/超鹿咖啡杯礼盒（咖啡杯、蛋白粉杯、冰箱贴）；</p>
          <p>请于2025年2月28日前凭此券至门店兑换实物；每个ID限购3份；</p>
          <p>3、实物券不可提现，不可退款，不可转赠。课包到账后亦不可提现，不可退款，不可转赠。</p>
          <p>4、活动最终解释权归超鹿所有。如有疑问可联系客服:400-700-5678。</p>
        </div>
      </van-popup>
      <template v-if="inviteUserInfo.shareTitle">
        <!-- 新人评价完推荐、签到推荐 的商品 -->
        <sign-in-recommended v-if="isSignInRecommended" :inviteUserInfo="inviteUserInfo" @buyCard="buyCard"
          @close="recommendedClose"></sign-in-recommended>
        <!-- 别人推荐的商品 -->
        <recommended-goods v-else :inviteUserInfo="inviteUserInfo" @buyCard="buyCard"
          @close="recommendedClose"></recommended-goods>
      </template>
      <payPopupCard :showPayPopup.sync="showPayPopup" :pay-data="payData" :pay-url="payUrl"></payPopupCard>
      <!-- 海报 组件 -->
      <common-share :value="isShareShow" :shareParams="shareParams" @close="isShareShow = false"
        @share-success="isShareShow = false" :shareItem="['minifriend', 'postermessage', 'save']"
        :miniSharePoster="appTypeStr === 'mini'">
        <template slot="createDom">
          <div class="canvascss">
            <img :src="baseImgUrl + 'sharebg.png'" class="canvascss_bg" />
            <div class="canvascss_code">
              <img v-if="userDetail.qrCode" :src="`data:image/png;base64,${userDetail.qrCode}`" />
              <div class="canvascss_code_text">扫码领取福利</div>
            </div>
            <div class="canvascss_info">
              <img :src="userDetail.headImg" class="canvascss_head" />
              <div class="canvascss_full">
                <div class="canvascss_name ellipsis-2" :style="`color: ${AI.nickNameHex};`">
                  {{ userDetail.nickName }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </common-share>
      <!-- 升级提示 -->
      <van-popup v-model="isUpgradeShow" closeable :overlay="true" style="background-color: transparent; width: 84vw">
        <div class="error-box">
          <h4 class="f36 fw6">更新提示</h4>
          <p class="f28">发现新版本，新功能需要升级至最新版本。</p>
          <div @click="upgrade" class="btn f32 fw6 row-center-center">去升级</div>
        </div>
      </van-popup>
      <!-- 确认购买 -->
      <van-popup v-model="buyPopShow" @click-overlay="buyPopShow = false" position="bottom" safe-area-inset-bottom
        class="pop-item">
        <div class="buy-title f32 fw5">
          确认购买
          <van-icon name="cross" class="buy-close f36" @click="buyPopShow = false" />
        </div>
        <div class="cbox">
          <div class="f28 fw6 mb10">购买商品</div>
          <div class="f24" style="color: #666;">{{ isPresale(buyGoods.id) ? '当前礼包为预售商品，1月21日后可至线下门店自取。' : '实物礼包仅支持线下门店自取'
          }}
          </div>
          <div class="goods-line">
            <div class="goods-img">
              <img :src="checkBuyGood.goodsImg" />
            </div>
            <div class="goods-info">
              <div class="goods-name fw6">{{ checkBuyGood.goodsName }}</div>
              <div class="time f20" v-if="checkBuyGood.goodsText">{{ checkBuyGood.goodsText }}</div>
              <div class="labels row-start-center" v-if="spliceFun(checkBuyGood.goodsTag).length">
                <div class="label row-center-center f20 fw6" v-for="(item, index) in spliceFun(checkBuyGood.goodsTag)"
                  :key="index">
                  {{ item }}</div>
              </div>
            </div>
            <div class="price" style="font-weight: 300;">
              <span class="f22" style="margin-top: -6px;">价值</span><span class="f24">{{ checkBuyGood.goodsPrice }}元</span>
            </div>
          </div>
          <div class="goods-line">
            <div class="goods-img">
              <img :src="checkBuyGood.sendGoodsImg" />
            </div>
            <div class="goods-info">
              <div class="goods-name fw6">{{ checkBuyGood.sendGoodsName }}</div>
              <div class="time f20" v-if="checkBuyGood.sendGoodsText">{{ checkBuyGood.sendGoodsText }}</div>
              <div class="labels row-start-center" v-if="spliceFun(checkBuyGood.sendTag).length">
                <div class="label row-center-center f20 fw6" v-for="(item, index) in spliceFun(checkBuyGood.sendTag)"
                  :key="index">
                  {{ item }}</div>
              </div>
            </div>
            <div class="price" style="font-weight: 300;">
              <span class="f22" style="margin-top: -6px;">价值</span><span class="f24">{{ checkBuyGood.sendPrice }}元</span>
            </div>
          </div>
          <div class="f28 fw6 mt30 mb10">自取门店</div>
          <div class="f24" style="color: #666;">请选择方便前往的门店，到店找教练核销兑换。</div>
          <div @click="showCV" class="venue-box">
            <van-icon name="location" /><span class="pub_onefull ml10 fw6">{{ choseVenueName || '请选择门店进行领取' }}</span>
            <van-icon name="arrow" />
          </div>
        </div>
        <div :class="['next', 'fw6']" @click="goPay">立即支付<span class="s1">￥{{ buyItem.salePrice }}</span><s class="s2">￥{{
          buyItem.originPrice }}</s></div>
      </van-popup>
      <!-- 门店选择 -->
      <CityVenue ref="refCityVenue" :cityAndVenue="cityAndVenue" @setVenue="setVenue" :justOpen="true" />
      <!-- 门店确认、切换 -->
      <van-popup v-model="cityConfirmShow" position="bottom" safe-area-inset-bottom class="pop-city-c" closeable>
        <img class="icon" src="https://img.chaolu.com.cn/ACT/88buy-preheat-2024/making/tips.png" alt="" />
        <p class="tips">
          您当前购买的产品，仅限<span v-show="cityName">{{ cityName }}</span>所有门店使用，请确认后再付款！
        </p>
        <div class="btn">
          <div @click="changeCityShowFun">切换城市</div>
          <div @click="citySure">确认</div>
        </div>
      </van-popup>
      <van-popup v-model="cityChangeShow" position="bottom" safe-area-inset-bottom class="pop-change" closeable
        @close="cityChangeClose">
        <p class="p-title">切换城市</p>
        <div class="city-list">
          <p v-for="(item, key) in cityJson" :class="key == unQueryCityId ? 'act' : ''" @click="unQueryCityId = key">{{
            item }}
          </p>
        </div>
        <div class="btn">
          <p>选择指定城市，卡仅限所选定的城市使用。</p>
          <p @click="comfrimcCityFun">确认</p>
        </div>
      </van-popup>
      <!-- 我的礼包 -->
      <van-popup v-model="giftShow" @click-overlay="giftShow = false" position="bottom" safe-area-inset-bottom
        class="pop-item-gift">
        <div class="buy-title f32 fw5">
          我的礼包
          <van-icon name="cross" class="buy-close f36" @click="giftShow = false" />
        </div>
        <div class="f24 red tc mb20" style="color: #EE4832;">请在对应门店找教练核销领取</div>
        <div class="gift-list">
          <div class="gift" v-for="(i, index) in giftList">
            <p class="title">{{ i.goodsName }}</p>
            <p><span>{{ i.payTime }} 下单</span><span class="sprice">¥{{ i.payAmount }}</span></p>
            <p class="status">
              <span>{{ i.venueName }} 自取</span>
              <span v-if='i.coachName' class="coachName">{{ i.coachName }}推荐</span>
              <span>{{ orderStatus[i.status] }}</span>
            </p>
            <p class="line" v-if="i.status == 1">
              <span><span v-if="checkIsPresale(i.goodsName)" style="color: #EE4832;">预售商品 | 1月21日后可到店自取</span></span>
              <span v-if="i.status == 1" class="change-venue" @click="giftChangeVenue(index)">更改领取门店</span>
            </p>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { newAppBack, initBack, appPay, appPaySuccess, appOpenWeb, upgrade, getAppVersion } from '@/lib/appMethod'
import { compareVersion } from '@/lib/utils'

import navBar from '@/components/nav-bar/nav-bar'
import commonShare from '@/components/commonShare/newShare'
import styleOneCard from './components/style-one-card'
import countDown from './components/count-down'
import recommendedGoods from './components/recommended-goods'
import signInRecommended from './components/sign-in-recommended'
import payPopupCard from '@/components/pay-popup-card'
import CityVenue from "@/components/cl-city-venue/cityVenue.vue";
import userMixin from '@/mixin/userMixin'
import headerMixin from '@/mixin/headerMixin'
import wx from 'weixin-js-sdk'

const miniPath = `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/new-year-buy-2025`
const webPath = `${window.location.origin}/#/superdeer-activity/new-year-buy-2025`

export default {
  components: {
    navBar,
    commonShare,
    styleOneCard,
    countDown,
    recommendedGoods,
    signInRecommended,
    payPopupCard,
    CityVenue,
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {
      baseImgUrl: 'https://img.chaolu.com.cn/ACT/new-year-buy-2025/',
      itembg: ['moon.png', 'team.png', 'p1.png', 'p2.png', 'p3.png'],
      shopId: '', // 活动id
      AI: {
        // 活动总信息
        activityTrailer: {}, // 预告页
        partitions: {},
        shopStyle: {},
      },

      barHeight: 0,
      navHeight: 0,

      groupIndex: 0, // 分组的下标
      shareParams: {
        title: '超鹿春节购',
        miniImage: 'https://img.chaolu.com.cn/ACT/new-year-buy-2025/mini.png',
        path: '',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      userDetail: {}, //分享的用户信息
      inviteUserInfo: {}, // 邀请人信息

      isShareShow: false,
      isRuleShow: false,
      address: {}, // 实体卡情况下 收货地址
      isUpgradeShow: false, // 升级提示

      buyGoods: {}, // 当前购买的商品 包含多个规格
      buyItem: {}, // 当前购买的规格
      showPayPopup: false, // 权益卡选择优惠券弹窗
      payData: {}, // 支付参数
      payUrl: '', // 支付回跳链接

      activityStatus: '', // 活动状态
      goodsStatusMap: {}, // 商品对应状态
      goodsPriceStatusMap: {}, // 商品对应的价格状态

      userExpansionSourceId: '',//获客来源--用拓crm
      buyPopShow: false, //确认购买
      itemIndex: 0,
      btnJson: {
        'NOT_STARTED': '待开启',
        'EXPIRED': '已结束',
        'SOLD_OUT': '库存不足',
        'HAVE_BUY': '已达限购',
        'CAN_BUY': '马上抢',
      },
      cityConfirmShow: false,
      cityChangeShow: false,
      unQueryCityId: '',
      cityJson: {
        3: '福州市',
        '036652995ac5451fbb6791c3491e1c2d': '厦门市',
        '818447834079563776': '广州市'
      },
      checkBuyGood: {},
      choseVenueId: '',
      choseVenueName: '',
      giftShow: false,
      giftList: [],
      orderStatus: ['', '支付成功', '退款', '部分退款', '已领取'],
      cityAndVenue: [],
      venueType: 'buy',
      giftItem: {}
    }
  },
  computed: {
    // 当前菜单下的商品 tab
    curGoods() {
      let c = this.AI.partitions.subfieldsInfos ? this.AI.partitions.subfieldsInfos[0] : { groupInfos: [], promotionalInfo: {} }
      c = JSON.parse(JSON.stringify(c))
      c.groupInfos[0].goodsInfos.push(...this.AI.partitions.subfieldsInfos[1].groupInfos[0].goodsInfos)
      // 处理 卡片上 标签问题 逗号隔开的字符改为 数组
      c.groupInfos.map((group) => group.goodsInfos.map((good) => (good.productTags = Array.isArray(good.productTags) ? good.productTags : good.productTags ? good.productTags.split(',') : [])))
      return c
    },
    // 根据人群、所在城市 判断是否显示
    showWithCrow() {
      return (good) => {
        // 没有showWithCrow或者有的情况下状态可购买时显示
        return !good.showWithCrow || (good.showWithCrow && this.goodsStatusMap[good.id] === 'CAN_BUY')
      }
    },
    cityName() {
      return this.buyGoods && this.buyGoods.saleCity && this.buyGoods.saleCity.length ? this.buyGoods.saleCity.map(d => {
        return this.cityJson[d]
      }).join(',') : ''
    }
  },
  async created() {
    initBack()
    await this.$getAllInfo(['userId', 'cityId'])
    const q = this.$route.query

    // 存在推荐商品 需要强制登录
    if (q.goodsId && !this.userId) {
      this.$store.commit('openLoginPopup', true)
      return
    }

    // 若是教练邀请 获取教练id
    this.inviteTeacherId = q.teacherId || q.inviteTeacherId
    this.inviteUserId = q.inviteUserId
    this.userExpansionSourceId = q.userExpansionSourceId
    if (this.inviteTeacherId) {
      this.countPoint('411', '411', '40077', this.userId)
    }

    // 来源
    this.from = q.from
    this.queryCityId = q.shopCityId || this.cityId // 区别于mixin里的cityId
    // 本次商城的id
    this.shopId = q.id || '10161'

    if (this.appTypeStr == 'mini') {
      this.countPoint('411', '411', '40079', this.userId)
    } else {
      this.countPoint('411', '411', '40078', this.userId)
    }

    // 分享的链接
    this.shareParams.path = `${miniPath}&id=${this.shopId}`

    // 推荐弹窗  签到或评价会弹的
    this.isSignInRecommended = q.reason ? true : false

    // 获取活动总信息
    const r = await this.getActivity()
    if (r === '0') return
    this.getGiftList()

    // 海报的二维码
    if (this.userId) this.initQr()

    // 获取绑定关系
    this.getBindInfo()

    // 小程序卡片分享
    if (this.AI.shopStyle.shareStatus) {
      this.miniShare()
    }

    // 支付成功回调 小程序购买成功提示
    appPaySuccess().then(() => {
      this.paySuccessCallback()
    })
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && !this.AI.activityTrailer) {
        setTimeout(() => {
          this.getGoodStatus()
        }, 500)
      }
    })
  },
  methods: {
    newAppBack,
    upgrade,
    previewFinish() {
      this.getActivity()
    },
    setBarHeight(h) {
      this.barHeight = h || 0
    },
    toPath(path) {
      const p = path.split('?')[0]
      let p1 = path.split('?')[1] ? `&${path.split('?')[1]}` : ''

      if (this.inviteTeacherId) {
        p1 = `${p1}&inviteTeacherId=${this.inviteTeacherId}`
        path = `${path}&inviteTeacherId=${this.inviteTeacherId}`
      }
      if (this.inviteUserId) {
        p1 = `${p1}&inviteUserId=${this.inviteUserId}`
        path = `${path}&inviteUserId=${this.inviteUserId}`
      }

      const hasDomain = p.indexOf('http') > -1
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${hasDomain ? `${p}${p1}` : window.location.origin + '/#' + `${p}${p1}`}`,
        })
      }
      if (this.appTypeStr === 'and') {
        App.intentToActivity(
          JSON.stringify({
            className: '.ui.AppWebViewLaunchStandardActivity',
            data: [
              {
                key: 'url',
                value: path,
              },
              {
                key: 'removeTitle',
                value: '1',
              },
            ],
          })
        )
      }
      if (this.appTypeStr === 'ios') {
        hasDomain ? appOpenWeb('', path) : this.$router.push(p)
      }
    },
    checkCanBuy() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios
        .post(this.baseURLApp + '/activityShopOrder/checkBuy', {
          goodsId: this.buyGoods.id,
          goodsItemId: this.buyItem.id,
          shopId: this.shopId,
          userId: this.userId,
        })
        .then((r) => {
          this.$toast.clear()
          return r.data
        })
        .catch(() => {
          this.$toast.clear()
          return 0
        })
    },
    async buyCard(item) {
      // 点击购买 基础校验
      // 未登录
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      // item可能来自推荐弹窗组件item.isRecommended:true
      // 推荐的商品可能不是当前分组下的商品 所以goodsStatusMap无法使用
      this.buyGoods = item
      this.buyItem = item.itemInfos[0]
      this.buyItem.extendInfo = this.buyItem.extendInfo ? this.buyItem.extendInfo : {}

      if (this.activityStatus != 'in') {
        this.$toast('活动还没开始哦~')
        return
      }

      // 低版本 校验 （芝麻先享、自动续费）
      const version = await getAppVersion()
      if (['ALI_SAFE_PAY', 'AUTO_RENEW'].includes(this.buyItem.extendInfo.payType) && compareVersion(version, '4.34.10') < 0) {
        this.isUpgradeShow = true
        return
      }
      // 低版本 校验 （vip自助卡）
      if (this.buyGoods.goodsType === 27 && compareVersion(version, '4.51.10') < 0) {
        this.isUpgradeShow = true
        return
      }

      // 如果由推荐商品的弹窗触发的 必然是能购买的
      if (!item.isRecommended && this.goodsStatusMap[item.id] !== 'CAN_BUY') {
        this.$toast('不符合购买条件')
        return
      }

      // 支付前校验
      const res = await this.checkCanBuy()
      if (!res) {
        this.$toast('无法购买')
        return
      }
      this.countPoint('411', this.buyGoods.id, '40081', this.userId)
      this.buyItem.extendInfo.checkCity ? this.cityConfirmShow = true : this.getPPObj()
    },
    getPPObj() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.$axios
        .post(`${this.baseURLApp}/activityShopWeb/getGoodPop`, {
          goodsId: this.buyGoods.id,
          goodsItemId: this.buyItem.id,
          shopId: this.shopId,
          userId: this.userId,
        }).then((res) => {
          console.log(res.data)
          this.$toast.clear();
          this.buyPopShow = true
          this.checkBuyGood = res.data[0]
        }).catch(() => {
          this.$toast.clear();
        })
    },
    async payAction() {
      const payData = this.getPayParams()
      // 如果是教练分享单个商品的情况 支付完回到页面还是只能看到单商品的弹窗 因此回调链接加上goodsId
      const goodsId = this.buyGoods.isRecommended ? this.buyGoods.id : ''
      const url = `${this.shareParams.path}&userId=1&goodsId=${goodsId}&shopCityId=${this.queryCityId}`
      if (this.appTypeStr === 'mini') {
        payData.wxPayType = 'applet'
      } else {
        appPaySuccess().then(() => {
          this.paySuccessCallback()
        })
      }
      appPay(payData, url)
    },
    getPayParams() {
      return {
        subject: this.buyItem.productName,
        extendsJson: {
          appVersion: '2.1.11',
          shopId: this.shopId,
          activityShopGoodsId: this.buyGoods.id + '',
          activityShopGoodsItemId: this.buyItem.id,
          from: this.from,
          userAddressId: this.address.userAddressId,
          perUseVenueId: this.perUseVenueId,
          venueId: this.choseVenueId
        },
        totalAmount: this.buyItem.salePrice + '',
        venueId: '31',
        goodsNum: [12, 22].includes(this.buyGoods.goodsType) ? '1' : undefined,
        isApp: '1',
        type: this.buyGoods.goodsType, // 礼品卡12  课包10 实体卡22
        userId: this.userId,
        token: this.token,
        extendsType: '4',
        mouldId: this.buyItem.productId,
        whetherPrivacy: 0,
        originalPriceStr: '',
        title: this.appTypeStr === 'mini' ? '商品名称' : this.buyItem.productName,
      }
    },
    async timeFinish() {
      if (this.activityStatus == 'in') {
        this.activityStatus = 'end'
        this.getGoodStatus()
      } else {
        await this.getActivity()
      }
    },
    getBindInfo() {
      this.$axios
        .post(this.baseURLApp + '/userDistribute/bindUser', {
          hasWx: true,
          activityNo: this.shopId, // shopId就是老接口里的activityNo
          userId: this.userId,
          saleTeacherId: this.inviteTeacherId,
          saleUserId: this.inviteUserId,
        })
        .then((res) => {
          const d = res.data
          this.inviteUserInfo = d || {}
          this.inviteTeacherId = d && d.shareTeacherId
          if (this.inviteTeacherId) {
            this.getTeacherVenue()
          }
        })
    },
    getTeacherVenue() {
      this.$axios.post(this.baseURLApp + '/teacher/getTeacherDetail',
        { teacherId: this.inviteTeacherId }
      ).then((res) => {
        this.cityAndVenue = [res.data.venueCityId || '3', res.data.venueId || '']
        this.choseVenueId = res.data.venueId || ''
        this.choseVenueName = res.data.venueName || ''
      })
    },
    getActivity() {
      return this.$axios
        .post(`${this.baseURLApp}/activityShopWeb/getShop`, {
          cityId: this.queryCityId,
          shopId: this.shopId,
          userId: this.userId,
        })
        .then((res) => {

          // 活动总信息
          this.AI = res.data
          if (!this.AI.activityTrailer) {
            // 设置活动状态
            this.activityStatus = this.AI.diffStartTime > 0 ? 'notin' : this.AI.diffEndTime > 0 ? 'in' : 'end'
            // 设置页面标题
            document.title = this.AI.activityName
            // 兜底
            if (!this.AI.shopStyle.groupTitleImg) this.AI.shopStyle.groupTitleImg = 'https://img.chaolu.com.cn/ACT/anniversary-2023/coverbg.png'
            // 预加载海报图
            if (this.AI.shareImg) {
              let img = new Image()
              img.src = this.AI.shareImg
              img.onload = () => { }
            }

            this.getGoodStatus()
            // 获取滚动元素高度
            this.getHeight()
          }
        })
        .catch((res) => {
          this.$toast(res.msg)
          setTimeout(() => {
            this.newAppBack()
          }, 2000)
          return res.code
        })
    },
    // 获取当前菜单tab下商品的id集合 单独获取商品的购买状态 结果用id匹配 {34: 'CAN_BUY'}
    getGoodStatus() {
      const idArr = []
      this.curGoods.groupInfos.map((group) => {
        group.goodsInfos.map((goods) => {
          idArr.push(goods.id)
        })
      })
      const params = {
        goodsId: idArr,
        shopId: this.shopId,
        userId: this.userId,
      }
      // 获取按钮状态
      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsStatus`, params).then((res) => {
        this.goodsStatusMap = res.data.goodsStatusMap
        this.$toast.clear()

        // 判断 分栏底下的商品 是否因为人群关系 全部隐藏 此时 需要隐藏整个分栏
        this.curGoods.groupInfos.map((group) => {
          let show = false
          group.goodsInfos.map((goods) => {
            if (!goods.showWithCrow || (goods.showWithCrow && this.goodsStatusMap[goods.id] === 'CAN_BUY')) show = true
          })

          this.$set(group, 'show', show)
        })
      })

      // 获取价格的状态
      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodMaxDiscount`, params).then((res) => {
        this.goodsPriceStatusMap = res.data.goodsDiscountMap
      })
    },
    paySuccessCallback() {
      this.$toast('支付成功')
      setTimeout(() => {
        this.perUseVenueId = ''
        this.perVenueName = ''
        this.getGoodStatus()
      }, 500)
    },
    showShare() {
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      this.isShareShow = true
      this.countPoint('411', '411', '40080', this.userId)
    },
    initQr() {
      this.$axios
        .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
          token: this.token,
          userId: this.userId,
          width: 240,
          height: 240,
          webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
          scene: JSON.stringify({
            source: `activity_${this.shopId}`,
            id: this.shopId,
            webUrl: webPath,
          }),
        })
        .then((res) => {
          this.userDetail = res.data
        })
    },
    miniShare() {
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      })
    },
    openRule() {
      this.isRuleShow = true
    },
    getHeight() {
      this.$nextTick(() => {
        this.navHeight = this.$refs.nav ? this.$refs.nav.clientHeight : 0
      })
    },
    hexToRgba(hex, opacity) {
      if (!hex) return ''
      return `rgba(${parseInt('0x' + hex.slice(1, 3))}, ${parseInt('0x' + hex.slice(3, 5))}, ${parseInt('0x' + hex.slice(5, 7))}, ${opacity})`
    },
    recommendedClose() {
      this.getGoodStatus()
    },
    // 选择门店
    showCV() {
      this.venueType = 'buy'
      this.$refs.refCityVenue.popShow = true;
    },
    //门店弹出窗口返回的数据
    setVenue(item) {
      if (this.venueType == 'buy') {
        this.choseVenueId = item.id
        this.choseVenueName = item.label
      } else {
        this.giftChangeVenueFun(item)
      }
    },
    close() {
      this.buyPopShow = false
    },
    changeCityShowFun() {
      this.cityChangeShow = true
      this.cityConfirmShow = false
    },
    citySure() {
      this.cityConfirmShow = false;
      this.getPPObj()
    },
    cityChangeClose() {
      this.cityChangeShow = false
      this.unQueryCityId = this.queryCityId
    },
    comfrimcCityFun() {
      if (!this.unQueryCityId) {
        this.$toast('请选择');
        return;
      }
      this.queryCityId = this.unQueryCityId
      this.cityChangeShow = false
      this.getActivity()
    },
    spliceFun(str) {
      return str ? str.split(',') : []
    },
    goPay() {
      if (!this.choseVenueId) {
        this.$toast('请选择门店')
        return
      }
      this.payAction()
    },
    showGift() {
      this.getGiftList()
      this.giftShow = true
      this.countPoint('411', '411', '40082', this.userId)
    },
    getGiftList() {
      this.$axios.post(`${this.baseURLApp}/c/entity/card/pageUserGoods`, {
        pageNum: 0, pageSize: 999, receiveType: 1, shopId: this.shopId
      }).then((res) => {
        this.giftList = res.data
      })
    },
    giftChangeVenue(index) {
      this.giftItem = this.giftList[index]
      this.cityAndVenue = [this.giftItem.cityId, this.giftItem.venueId]
      this.venueType = 'gift'
      this.$refs.refCityVenue.popShow = true;
    },
    giftChangeVenueFun(item) {
      this.$axios.post(`${this.baseURLApp}/c/entity/card/updatePickupStore`, {
        payOrderNo: this.giftItem.payOrderNo, venueId: item.id, venueName: item.label
      }).then((res) => {
        this.$toast('修改成功')
        this.showGift()
      })
    },
    isPresale(id) {
      return id >= 5843 && id <= 5851
    },
    replaceFun(str, index) {
      if (this.queryCityId != '818447834079563776') {
        return index == 8 ? str.replace('-gz', '') : str
      }
      return str
    },
    checkIsPresale(str) {
      return /360|240/.test(str)
    }
  },
}
</script>
<style lang="less" scoped>
.marketing-preview {
  min-height: 100vh;
}

.marketing-template-2023 {
  min-height: 100vh;
  background: #fb7a37;
  padding-bottom: 80px;

  div {
    box-sizing: border-box;
  }

  img {
    width: 100%;
  }

  .template-content {
    width: 100%;
  }

  .background {
    position: relative;
    width: 100%;

    .head-img {
      display: block;
      min-height: 30px;
    }

    .top-button {
      position: absolute;
      right: 0;
      top: 238px;
      width: 88px;
      z-index: 99;

      .icon {
        width: 88px;
        height: 88px;
        object-fit: contain;
        margin-bottom: 32px;
      }

      .button {
        line-height: 56px;
        background: rgba(36, 40, 49, 0.3);
        border-radius: 40px 0 0 40px;
        color: #fff;
        width: 100%;
        text-align: center;
        margin-bottom: 32px;
        padding-left: 6px;
      }
    }

    .float-icon {
      position: absolute;
      right: 0;
      bottom: 165px;
      width: 126px;
      z-index: 99;
    }

    .down-time {
      position: absolute;
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }
  }

  .invite-block {
    width: 686px;
    height: 104px;
    margin: 20px 32px 0;
    background: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_bg_invite.png);
    background-size: 100% 100%;
    padding: 0 36px;
    color: #eeeeee;

    .invite-head {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
      background: #f5f5f5;
      flex-shrink: 0;
      margin-right: 20px;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .nav {
    width: 100%;
    position: relative;
    padding: 16px 32px 8px;

    .tm {
      max-width: 335px;
      min-width: 333px;
      flex: 1;
      margin-bottom: 16px;
      background: linear-gradient(180deg, #ffffff 0%, #ffe9e3 100%);
      border-radius: 12px;
      height: 100px;
      padding-left: 26px;
      color: #383e43;
      position: relative;
      overflow: hidden;

      &.small {
        max-width: 218px;
        min-width: 216px;
      }

      &.active {
        color: #9b270c;
        background: linear-gradient(180deg, #fffed8 0%, #ffe293 100%);
      }

      .ii {
        transform: scale(0.8);
        transform-origin: 0 50%;
        white-space: nowrap;
      }
    }
  }


  .end {
    text-align: center;
    color: #fff;
    position: relative;
    margin-top: 40px;

    &::after,
    &::before {
      content: '';
      vertical-align: middle;
      display: inline-block;
      width: 60px;
      height: 1px;
      background: #fff;
    }

    &::after {
      margin: -2px 0 0 29px;
    }

    &::before {
      margin: -2px 29px 0 0;
    }
  }

  .good-block {
    width: 100%;
    padding: 0 32px;

    .good {
      width: 100%;
      background-size: 100% 100%;
      height: 497px;
      margin-top: 50px;
      position: relative;

      &.itembg2,
      &.itembg5 {
        height: 594px;
        // margin-top: 50px;
      }

      &.itembg3,
      &.itembg6,
      &.itembg9 {
        height: 343px;
        margin-top: 0px;
      }

      &.itembg4,
      &.itembg7,
      &.itembg10 {
        height: 353px;
        margin-top: 0px;
      }

      &.itembg8 {
        height: 435px;
        margin-top: 0px;
      }

      .btn {
        position: absolute;
        bottom: 64px;
        right: 120px;
        width: 200px;
        height: 56px;
        border-radius: 50px;
        font-family: PuHuiTi;
        font-size: 28px;
        line-height: 56px;
        text-align: center;
        background: linear-gradient(0deg, #6D6161 0%, #BEB1B1 100%);
        color: #fff;

        &.CAN_BUY {
          background: linear-gradient(0deg, #E02E25 0%, #FF6E42 100%);
        }

      }
    }
  }

  .pop-rule {
    border-radius: 24px 24px 0 0;
    color: #242831;

    .pop-rule-title {
      height: 140px;
      text-align: center;
      line-height: 130px;
      position: relative;
      background: linear-gradient(180deg, #FFD9D5 0%, #FFFFFF 100%);

      .pop-rule-close {
        position: absolute;
        right: 32px;
        top: 38px;
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        background: rgba(39, 40, 49, 0.2);
      }
    }

    .pop-rule-content {
      padding: 0 52px;
      max-height: 900px;
      overflow: auto;
      padding-bottom: 60px;
      white-space: pre-line;

      p {
        margin: 10px 0px;
      }

      .pt {
        margin-top: 20px;
      }
    }
  }

  .pop-item {
    border-radius: 24px 24px 0 0;
    color: #242831;

    .buy-title {
      border-bottom: 1px solid #eeeeee;
      height: 97px;
      line-height: 97px;
      padding: 0 32px;
      text-align: center;
      position: relative;
    }

    .buy-close {
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);

    }

    .cbox {
      padding: 37px 32px 0;
      border-bottom: 1px solid #eee;
    }

    .buy-tips {
      color: #9aa1a9;
      margin: 4px 0 22px;
    }

    .goods-line {
      display: flex;
      margin: 22px 0px 0px;
      border: 3px solid #FFAC7F;
      background-color: #FFFAF7;
      border-radius: 16px;
      padding: 30px 18px;
      align-items: center;

      .goods-img {
        width: 144px;

        img {
          width: 100%;
          border-radius: 16px;
          display: block;
        }
      }

      .goods-info {
        flex: 1;
        margin-left: 30px;

        .goods-name {
          color: #242831;
          font-size: 30px;
        }

        .time {
          color: #242831;
          margin: 12px 0 20px;
          line-height: 1;
        }

        .labels {
          margin: 0 0 15px;

          .label {
            height: 30px;
            background: linear-gradient(90deg, #ffe8b5 0%, #ffd7c3 100%);
            padding: 0 13px;
            margin: 0 12px 0 0;
            color: #b12e1d;
            border-radius: 4px;
          }
        }
      }

      .price {
        background: url(https://img.chaolu.com.cn/ACT/new-year-buy-2025/price.png) no-repeat center center / 100% 100%;
        width: 130px;
        height: 130px;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: PuHuiTi;
      }
    }

    .venue-box {
      width: 100%;
      height: 128px;
      background-color: #F5F5F5;
      font-size: 28px;
      color: #A65123;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px;
      box-sizing: border-box;
      border-radius: 8px;
      margin: 25px 0px 40px;
    }


    .city-list {
      padding: 0 0 32px;

      .city-model {
        margin-top: 24px;
        height: 128px;
        background: #f5f5f5;
        border-radius: 8px;
        padding: 0 32px;

        &.disabled {
          color: #9aa1a9;
        }

        &.active {
          border: 4px solid #242831;

          .checkbox {
            width: 48px;
            height: 48px;
            background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/spring2023/check.png);
            background-size: 100% 100%;
            border: none;
            margin: 0;
          }
        }

        .checkbox {
          width: 32px;
          height: 32px;
          border: 1px solid #ddd;
          border-radius: 50%;
          margin-right: 8px;
        }

        .has-buy {
          width: 102px;
        }
      }
    }



    .next {
      height: 96px;
      background: #ffde00;
      border-radius: 8px;
      margin: 34px 32px 0;
      // text-align: center;
      margin-bottom: 34px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.grey {
        filter: grayscale(1);
      }

      .s1 {
        font-size: 32px;
        color: #EE4832;
        margin-left: 10px;
      }

      .s2 {
        font-size: 24px;
        color: #666666;
        margin-left: 15px;
      }
    }
  }

  .pop-item-gift {
    border-radius: 24px 24px 0 0;
    color: #242831;
    background-color: #F5F5F5;

    .buy-title {
      height: 97px;
      line-height: 97px;
      padding: 0 32px;
      text-align: center;
      position: relative;
      background: url(https://img.chaolu.com.cn/ACT/new-year-buy-2025/line.png) no-repeat;
      background-size: 150px 18px;
      background-position: 300px 53px;
    }

    .buy-close {
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);
    }

    .gift-list {
      max-height: 60vh;
      overflow: scroll;
      margin-bottom: 60px;

      .gift {
        margin: 10px 32px 20px;
        background-color: #fff;
        padding: 36px 33px 26px;
        font-size: 22px;
        color: #000000;
        border-radius: 14px;
        line-height: 1.1;

        >p {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .title {
          font-size: 28px;
          color: #242831;
          margin-bottom: 26px;
          font-weight: 600;
        }

        .sprice {
          font-size: 28px;
          color: #EE4832;
        }

        .status {
          margin: 30px 0px 0px;

          .coachName {
            border-left: 1px solid #DDDDDD;
            padding-left: 16px;
            margin-left: 16px;
            flex: 1;
          }
        }

        .line {
          margin-top: 30px;
          padding-top: 24px;
          border-top: 1px solid #eee;
        }

        .change-venue {
          width: 200px;
          height: 60px;
          background: linear-gradient(0deg, #F35947 0%, #FBA27B 100%);
          border-radius: 30px;
          color: #fff;
          text-align: center;
          line-height: 60px;
          font-size: 24px;
        }
      }
    }
  }

  .pop-city-c {
    overflow: visible;
    padding-top: 200px;
    border-radius: 24px 24px 0px 0px;

    .icon {
      width: 273px;
      height: 260px;
      position: absolute;
      top: -100px;
      left: 50%;
      transform: translateX(-50%);
    }

    .tips {
      padding: 0px 100px 110px;
      font-size: 28px;
      line-height: 2;
      text-align: center;

      span {
        color: #F03C18;
        font-size: 36px;
        font-weight: bold;
        margin: 0 10px;
      }
    }

    .btn {
      padding: 26px 32px;
      text-align: center;
      line-height: 92px;
      height: 92px;
      font-size: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
      box-sizing: content-box;

      >div:first-child {
        border: 1px solid #EEEEEE;
        border-radius: 8px;
        width: 212px;
      }

      >div:last-child {
        background-color: #FFDE00;
        border: 1px solid #FFDE00;
        border-radius: 8px;
        width: 442px;
        font-weight: 600;
      }
    }
  }

  .pop-change {
    border-radius: 24px 24px 0px 0px;

    .p-title {
      font-size: 28px;
      line-height: 96px;
      height: 98px;
      border-bottom: 1px solid #EEEEEE;
      text-align: center;
      font-weight: 600;
    }

    .city-list {
      padding: 35px 32px 78px;

      p {
        padding: 0px 33px;
        font-size: 32px;
        background-color: #F5F5F5;
        border-radius: 8px;
        height: 128px;
        line-height: 128px;

        &.act {
          border: 2px solid #232831;
        }
      }

      p+p {
        margin-top: 32px;
      }
    }

    .btn {
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
      box-sizing: content-box;
      padding: 27px 32px;
      text-align: center;

      >p:first-child {
        font-size: 22px;
        color: #68413C;
        margin-bottom: 20px;
      }

      >p:last-child {
        background-color: #FFDE00;
        border: 1px solid #FFDE00;
        border-radius: 8px;
        height: 92px;
        font-weight: 600;
        line-height: 92px;
      }
    }
  }

  .error-box {
    width: 630px;
    background: #ffffff;
    border-radius: 12px;
    margin: 0 auto;
    padding: 0 40px 48px;

    h4 {
      text-align: center;
      padding-top: 40px;
    }

    p {
      margin: 32px auto 56px;
      width: 510px;
      color: #3c454e;
      line-height: 44px;
    }

    .btn {
      margin: 0 auto;
      width: 510px;
      height: 96px;
      background: #ffde00;
      border-radius: 8px;
    }

    span {
      color: #f03c18;
    }
  }

  .canvascss {
    width: 300PX;
    height: 539PX;
    margin: 0 auto;
    box-sizing: border-box;

    .canvascss_bg {
      width: 100%;
      height: 100%;
    }

    .canvascss_code {
      position: absolute;
      left: 60PX;
      bottom: 7PX;
      width: 66PX;
      height: 71PX;
      background: #fff;
      z-index: 99;
      border-radius: 5PX;
      overflow: hidden;
      border: 1PX solid #E1E1E1;

      img {
        width: 100%;
        height: 65PX;
        position: relative;
        top: -2PX;
      }

      .canvascss_code_text {
        position: absolute;
        bottom: 4PX;
        left: 0;
        right: 0;
        text-align: center;
        line-height: 1;
        font-size: 8PX;
        color: #000000;
      }
    }

    .canvascss_info {
      position: absolute;
      left: 133PX;
      bottom: 26PX;
      padding: 3PX;
      width: 108PX;
      height: 51PX;
      overflow: hidden;
      align-items: center;
      display: flex;

      .canvascss_head {
        width: 45PX;
        height: 45PX;
        border-radius: 7PX;
        margin-right: 6PX;
      }

      .canvascss_full {
        font-weight: bold;
        font-size: 11PX;
        color: #000000;
        flex: 1;

        .canvascss_name {
          line-height: 1.3;
        }
      }
    }
  }

  .red {
    color: #ee4832;
  }
}

/deep/ .red {
  color: #ff6e00;
}
</style>
